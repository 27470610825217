import { makeStyles } from "@material-ui/core";
import { BrandsEnum } from "../../enums";
export const useStyles = makeStyles((theme) => ({
    "@global": {
        html: {
            scrollBehavior: "smooth",
            "& body": {
                "& .ifchat-skoda.ifchat-banner.ifchat-tabbanner .ifchat-tab .ifchat-tab-text": {
                    backgroundColor: (theme.brand === BrandsEnum.Skoda) &&
                        theme?.colorCard?.primary,
                },
                "-webkit-font-smoothing": "subpixel-antialiased",
                "& .grecaptcha-badge": {
                    visibility: "hidden!important"
                }
            },
        },
        ".text-area": {
            "& h1": {
                fontSize: theme.typography.h1.fontSize,
                lineHeight: theme.typography.h1.lineHeight,
                fontFamily: theme.typography.h1.fontFamily,
                fontWeight: 400,
                ...theme.customStyling?.global?.textArea?.h1,
            },
            "& h2": {
                fontSize: theme.typography.h2.fontSize,
                lineHeight: theme.typography.h2.lineHeight,
                fontFamily: theme.typography.h2.fontFamily,
                fontWeight: 400,
                ...theme.customStyling?.global?.textArea?.h2,
            },
            "& h3": {
                fontSize: theme.typography.h3.fontSize,
                lineHeight: theme.typography.h3.lineHeight,
                fontFamily: theme.typography.h3.fontFamily,
                fontWeight: 400,
                ...theme.customStyling?.global?.textArea?.h3,
            },
            "& h4": {
                fontSize: theme.typography.h4.fontSize,
                lineHeight: theme.typography.h4.lineHeight,
                fontFamily: theme.typography.h4.fontFamily,
                fontWeight: 400,
            },
            "& h5": {
                fontSize: theme.typography.h5.fontSize,
                lineHeight: theme.typography.h5.lineHeight,
                fontFamily: theme.typography.h5.fontFamily,
                fontWeight: 400,
            },
            "& a": {
                color: theme.colorCard?.primary || theme.palette?.primary?.main,
                "&:hover": {
                    color: theme.colorCard?.primaryHover ??
                        theme?.textBlock?.linkHoverColor ??
                        theme.palette?.text?.primary,
                },
                "&:active": {
                    color: theme.colorCard?.info || theme.palette?.text?.hint,
                },
                ...theme.customStyling?.global?.textArea?.a,
            },
            "& p": {
                marginTop: 16,
                marginBottom: 16,
            },
            "& .legal-text": {
                fontSize: "12px",
                lineHeight: "20px",
                ...theme.customStyling?.global?.textArea?.legalText,
            },
            "& .preamble": {
                fontSize: "1.125rem",
            },
            "& .button-primary": {
                boxSizing: "border-box",
                appearance: "none",
                userSelect: "none",
                transition: "all 0.35s ease-out",
                display: "inline-flex",
                cursor: "pointer",
                padding: "0 24px",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1rem",
                marginTop: 8,
                borderRadius: theme.shape.borderRadius,
                border: `1px solid ${theme.colorCard?.primary || theme.palette?.primary?.main}`,
                background: theme.colorCard?.primary || theme.palette?.primary?.main,
                color: theme.colorCard?.background || theme.palette?.primary?.contrastText,
                minHeight: "48px",
                minWidth: "200px",
                lineHeight: "24px",
                textDecoration: "none",
                [theme.breakpoints.down("xs")]: {
                    width: "100%",
                },
                fontFamily: theme.typography.fontFamily,
                "&:hover, &:focus": {
                    backgroundColor: theme.colorCard?.primaryHover || theme.palette?.primary?.dark,
                    border: `1px solid ${theme.colorCard?.primaryHover || theme.palette?.primary?.dark}`,
                    textDecoration: "none",
                    color: theme.colorCard?.background ||
                        theme.palette?.primary?.contrastText,
                },
                "& a": {
                    textDecoration: "none",
                    color: theme.colorCard?.background ||
                        theme.palette?.primary?.contrastText,
                    "&:hover": {
                        textDecoration: "none",
                        color: theme.colorCard?.background ||
                            theme.palette?.primary?.contrastText,
                    },
                },
                ...theme.customStyling?.global?.primaryButton,
            },
            "& .button-secondary": {
                boxSizing: "border-box",
                appearance: "none",
                userSelect: "none",
                transition: "all 0.35s ease-out",
                display: "inline-flex",
                cursor: "pointer",
                padding: "0 24px",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "1rem",
                borderRadius: theme.shape.borderRadius,
                border: `1px solid ${theme.colorCard?.primary || theme.palette?.primary?.main}`,
                background: theme.colorCard?.background || theme.palette?.primary?.contrastText,
                color: theme.colorCard?.primary || theme.palette?.primary?.main,
                minHeight: "48px",
                minWidth: "200px",
                lineHeight: "24px",
                textDecoration: "none",
                [theme.breakpoints.down("xs")]: {
                    width: "100%",
                },
                "&:hover, &:focus": {
                    border: `1px solid ${theme.colorCard?.primaryHover || theme.palette?.primary?.dark}`,
                    color: theme.colorCard?.background ||
                        theme.palette?.primary?.contrastText,
                    backgroundColor: theme.colorCard?.primaryHover || theme.palette?.primary?.dark,
                    textDecoration: "none",
                },
                "& a": {
                    textDecoration: "none",
                    color: theme.colorCard?.primary || theme.palette?.primary?.main,
                    "&:hover": {
                        textDecoration: "none",
                        color: theme.colorCard?.primary || theme.palette?.primary?.main,
                    },
                },
                ...theme.customStyling?.global?.secondaryButton,
            },
            "& .mob-button": {
                display: "none",
                [theme.breakpoints.down("xs")]: {
                    display: "flex",
                },
            },
            "& .table-wrapper": {
                overflowX: "auto",
                maxWidth: "calc(100vw - 32px)",
                "& * th": {
                    padding: 24,
                    fontWeight: "normal",
                },
            },
            "& table": {
                borderColor: theme.textBlock?.tableBorderColor || theme.colorCard?.dividerLine,
                background: "#ffffff",
                color: theme.colorCard?.bodyText || theme.palette?.text?.secondary,
                [theme.breakpoints.down("sm")]: {
                    position: "relative",
                },
            },
            "& .table-colored-column": {
                "& tr": {
                    "& td:first-child": {
                        fontSize: "1.125rem",
                        fontFamily: theme.typography.fontFamily + " !important",
                        background: theme.textBlock?.tableBackground ||
                            theme.colorCard?.background2,
                    },
                    "& td": {
                        textAlign: "left",
                        verticalAlign: "top",
                        minHeight: "56px !important",
                        height: "fit-content",
                        [theme.breakpoints.down("xs")]: {
                            wordBreak: "break-word",
                        },
                        [theme.breakpoints.up("sm")]: {},
                    },
                },
            },
            "& .table-even-colored": {
                "& tr": {
                    "&:nth-child(even) ": {
                        background: theme.textBlock?.tableBackground ||
                            theme.colorCard?.background2,
                    },
                    "& td": {
                        minHeight: "56px !important",
                        padding: "8px 24px",
                        [theme.breakpoints.down("xs")]: {
                            wordBreak: "break-word",
                            padding: "8px",
                        },
                        [theme.breakpoints.up("sm")]: {
                            padding: "8px 16px",
                        },
                    },
                },
            },
            "& .table-odd-colored": {
                "& tr": {
                    "&:nth-child(odd) ": {
                        background: theme.textBlock?.tableBackground ||
                            theme.colorCard?.background2,
                    },
                    "& td": {
                        height: "56px !important",
                        padding: "8px 24px",
                        [theme.breakpoints.down("xs")]: {
                            wordBreak: "break-word",
                            padding: "8px",
                        },
                        [theme.breakpoints.up("sm")]: {
                            padding: "8px 16px",
                        },
                    },
                },
            },
            "& .table-odd-colored-four-column": {
                "& tr": {
                    "&:nth-child(odd) ": {
                        background: theme.textBlock?.tableBackground ||
                            theme.colorCard?.background2,
                        "& td:first-child": {
                            background: theme.textBlock?.tableBackground ||
                                theme.colorCard?.background2,
                        },
                    },
                    "&:nth-child(even) ": {
                        background: "#ffffff",
                        "& td:first-child": {
                            background: "#ffffff",
                        },
                    },
                    "& td": {
                        height: "56px !important",
                        padding: "8px 24px",
                        [theme.breakpoints.down("xs")]: {
                            wordBreak: "break-word",
                            padding: "8px 4px",
                        },
                        [theme.breakpoints.up("sm")]: {
                            padding: "8px 16px",
                        },
                    },
                },
            },
            "& .table-even-colored-four-column": {
                "& tr": {
                    "&:nth-child(even) ": {
                        background: theme.textBlock?.tableBackground ||
                            theme.colorCard?.background2,
                        "& td:first-child": {
                            background: theme.textBlock?.tableBackground ||
                                theme.colorCard?.background2,
                        },
                    },
                    "&:nth-child(odd) ": {
                        background: "#ffffff",
                        "& td:first-child": {
                            background: "#ffffff",
                        },
                    },
                },
                "& td": {
                    height: "56px !important",
                    padding: "8px 24px",
                    [theme.breakpoints.down("xs")]: {
                        wordBreak: "break-word",
                        padding: "8px 4px",
                    },
                    [theme.breakpoints.up("sm")]: {
                        padding: "8px 16px",
                    },
                },
            },
            "& .table-even-colored-three-column": {
                "& tr": {
                    [theme.breakpoints.up("sm")]: {
                        "&:nth-child(even) ": {
                            background: theme.textBlock?.tableBackground ||
                                theme.colorCard?.background2,
                        },
                    },
                    [theme.breakpoints.down("sm")]: {
                        "&:nth-child(odd) ": {
                            background: "#ffffff",
                        },
                        "&:nth-child(even) ": {
                            background: theme.textBlock?.tableBackground ||
                                theme.colorCard?.background2,
                        },
                    },
                    "& td": {
                        height: "56px !important",
                        padding: "8px 24px",
                        [theme.breakpoints.down("xs")]: {
                            wordBreak: "break-word",
                            padding: "8px",
                        },
                        [theme.breakpoints.up("sm")]: {
                            padding: "8px 16px",
                        },
                    },
                },
            },
            "& .table-odd-colored-three-column": {
                "& tr": {
                    [theme.breakpoints.up("sm")]: {
                        "&:nth-child(odd) ": {
                            background: theme.textBlock?.tableBackground ||
                                theme.colorCard?.background2,
                        },
                    },
                    [theme.breakpoints.down("sm")]: {
                        "&:nth-child(even) ": {
                            background: "#ffffff",
                        },
                        "&:nth-child(odd) ": {
                            background: theme.textBlock?.tableBackground ||
                                theme.colorCard?.background2,
                        },
                    },
                    "& td": {
                        height: "56px !important",
                        padding: "8px 24px",
                        [theme.breakpoints.down("xs")]: {
                            wordBreak: "break-word",
                            padding: "8px",
                        },
                        [theme.breakpoints.up("sm")]: {
                            padding: "8px 16px",
                        },
                    },
                },
            },
            "& .table-headline": {
                color: `${theme.colorCard?.inputText || theme.palette?.text?.primary} !important`,
                "& tr:first-child": {
                    background: theme.textBlock?.tableBackground || theme.colorCard?.background2,
                    "& td, &th": {
                        background: `${theme.textBlock?.tableBackground || theme.colorCard?.background2} !important`,
                        fontSize: "1.125rem !important",
                        fontFamily: theme.typography.fontFamily + " !important",
                    },
                },
            },
            "& .table-one-to-two-one-mobile": {
                color: theme.colorCard?.bodyText || theme.palette?.text?.secondary,
                borderColor: theme.textBlock?.tableBorderColor || theme.colorCard?.dividerLine,
                width: "100% !important",
                [theme.breakpoints.down("sm")]: {
                    width: "100% !important",
                },
                "& tr": {
                    [theme.breakpoints.down("sm")]: {
                        width: "100% !important",
                        height: "fit-content",
                        minHeight: "72px",
                    },
                    "& td:first-child": {
                        width: "30% !important",
                        [theme.breakpoints.down("sm")]: {
                            width: "80% !important",
                            padding: "8px 10% !important",
                            minHeight: "56px",
                        },
                    },
                    "& td": {
                        textAlign: "left",
                        padding: "8px 24px",
                        minHeight: "56px !important",
                        verticalAlign: "top",
                        [theme.breakpoints.down("sm")]: {
                            padding: "8px 10% !important",
                            width: "80% !important",
                            height: "fit-content !important",
                            display: "flex",
                            flexDirection: "column",
                            [theme.breakpoints.down("xs")]: {
                                wordBreak: "break-word",
                                padding: "8px",
                            },
                        },
                    },
                },
            },
            "& .table-one-to-one-one-mobile": {
                color: theme.colorCard?.bodyText || theme.palette?.text?.secondary,
                borderColor: theme.textBlock?.tableBorderColor || theme.colorCard?.dividerLine,
                width: "100% !important",
                [theme.breakpoints.down("sm")]: {
                    width: "100% !important",
                },
                "& tr": {
                    [theme.breakpoints.down("sm")]: {
                        width: "100% !important",
                        height: "fit-content",
                        minHeight: "56px",
                    },
                    "& td:first-child": {
                        width: "50% !important",
                        [theme.breakpoints.down("sm")]: {
                            display: "flex",
                            width: "80% !important",
                            padding: "8px 10% !important",
                        },
                    },
                    "& td": {
                        textAlign: "left",
                        minHeight: "56px !important",
                        padding: "8px 24px",
                        verticalAlign: "top",
                        [theme.breakpoints.down("sm")]: {
                            width: "80% !important",
                            padding: "8px 10% !important",
                            height: "fit-content !important",
                            display: "flex",
                            flexDirection: "column",
                        },
                        [theme.breakpoints.down("xs")]: {
                            wordBreak: "break-word",
                            padding: "8px",
                        },
                    },
                },
            },
            "& .table-one-to-one-two-mobile": {
                color: theme.colorCard?.bodyText || theme.palette?.text?.secondary,
                borderColor: theme.textBlock?.tableBorderColor || theme.colorCard?.dividerLine,
                width: "100% !important",
                [theme.breakpoints.down("sm")]: {
                    width: "100% !important",
                },
                "& tr": {
                    [theme.breakpoints.down("sm")]: {
                        width: "100% !important",
                        height: "fit-content",
                    },
                    "& td:first-child": {
                        maxWidth: "50%",
                        width: "50% !important",
                    },
                    "& td": {
                        height: "56px !important",
                        padding: "8px 24px",
                        textAlign: "left",
                        verticalAlign: "top",
                        [theme.breakpoints.down("sm")]: {
                            padding: "8px 16px",
                        },
                        [theme.breakpoints.down("xs")]: {
                            wordBreak: "break-word",
                            padding: "8px",
                        },
                    },
                },
            },
            "& .table-one-to-two-two-mobile": {
                color: theme.colorCard?.bodyText || theme.palette?.text?.secondary,
                width: "100% !important",
                wordBreak: "break-word",
                border: `1px solid ${theme.textBlock?.tableBorderColor || theme.colorCard?.dividerLine}`,
                borderWidth: "0.01px",
                [theme.breakpoints.down("sm")]: {
                    width: "100% !important",
                },
                "& tr": {
                    border: `1px solid ${theme.textBlock?.tableBorderColor || theme.colorCard?.dividerLine}`,
                    borderWidth: "0.01px",
                    [theme.breakpoints.down("sm")]: {
                        width: "100% !important",
                        height: "fit-content",
                    },
                    "& td:first-child": {
                        width: "30% !important",
                        border: `1px solid ${theme.textBlock?.tableBorderColor ||
                            theme.colorCard?.dividerLine}`,
                        borderWidth: "0.01px",
                    },
                    "& td": {
                        height: "56px !important",
                        textAlign: "left",
                        padding: "8px 24px",
                        border: `1px solid ${theme.textBlock?.tableBorderColor ||
                            theme.colorCard?.dividerLine}`,
                        borderWidth: "0.01px",
                        verticalAlign: "top",
                        [theme.breakpoints.down("sm")]: {
                            height: "fit-content !important",
                            padding: "8px 16px",
                        },
                        [theme.breakpoints.down("xs")]: {
                            wordBreak: "break-word",
                            padding: "8px",
                        },
                    },
                },
            },
            "& .table-three-columns": {
                color: theme.colorCard?.bodyText || theme.palette?.text?.secondary,
                border: "none",
                width: "100% !important",
                [theme.breakpoints.down("sm")]: {
                    width: "100% !important",
                    height: "fit-content !important",
                    overflow: "auto",
                    overflowScrolling: "touch",
                    WebkitOverflowScrolling: "touch",
                },
                "& tr": {
                    [theme.breakpoints.down("sm")]: {
                        width: "100% !important",
                        height: "fit-content",
                    },
                    "& td": {
                        minHeight: "56px !important",
                        height: "fit-content !important",
                        textAlign: "center",
                        padding: "8px 10% !important",
                        verticalAlign: "middle",
                        border: "none",
                        width: "33% !important",
                        [theme.breakpoints.down("sm")]: {
                            minWidth: "100px !important",
                            height: "fit-content !important",
                            padding: "8px 5% !important",
                        },
                        [theme.breakpoints.down("xs")]: {
                            wordBreak: "break-word",
                            padding: "8px",
                        },
                    },
                },
            },
            "& .table-four-columns": {
                color: theme.colorCard?.bodyText || theme.palette?.text?.secondary,
                border: "none",
                [theme.breakpoints.down("sm")]: {
                    width: "100%",
                    height: "fit-content !important",
                    overflow: "auto",
                    overflowScrolling: "touch",
                    WebkitOverflowScrolling: "touch",
                    [theme.breakpoints.down("sm")]: {
                        marginLeft: "-24px",
                        marginRight: "-24px",
                    },
                },
                "& tr": {
                    height: "56px !important",
                    [theme.breakpoints.down("sm")]: {
                        height: "fit-content",
                        minHeight: "72px",
                    },
                    "& td": {
                        textAlign: "center",
                        padding: "8px 24px",
                        verticalAlign: "middle",
                        border: "none",
                        width: "25% !important",
                        [theme.breakpoints.down("sm")]: {
                            minWidth: "100px !important",
                            height: "fit-content !important",
                        },
                        [theme.breakpoints.down("xs")]: {
                            wordBreak: "break-word",
                        },
                    },
                },
            },
            "& .table-four-columns-sticky": {
                color: theme.colorCard?.bodyText || theme.palette?.text?.secondary,
                border: "none",
                "& tr": {
                    [theme.breakpoints.down("sm")]: {
                        position: "relative",
                    },
                    "& td:first-child": {
                        [theme.breakpoints.down("sm")]: {
                            position: "sticky",
                            top: "auto",
                            left: "0",
                        },
                    },
                    "& td": {
                        height: "56px !important",
                        padding: "8px 24px",
                        [theme.breakpoints.down("sm")]: {
                            padding: "8px 16px",
                        },
                        [theme.breakpoints.down("xs")]: {
                            wordBreak: "break-word",
                            padding: "8px 4px",
                        },
                    },
                },
            },
            "& ul": {
                fontSize: "1rem",
                paddingInlineStart: "0",
                marginLeft: 0,
                listStyleType: "none",
                "& li": {
                    lineHeight: theme.spacing(3) + "px",
                    paddingLeft: theme.spacing(5) + "px",
                    marginLeft: theme.spacing(3) + "px",
                },
                "&.bullet": {
                    "& li": {
                        marginLeft: 0,
                        marginBottom: 18,
                        background: `url("data:image/svg+xml;utf8,${theme.icons?.bulletIconCSS}") no-repeat left`,
                    },
                },
                "&.check": {
                    paddingLeft: 0,
                    listStyleType: "none",
                    "& li": {
                        background: `url("data:image/svg+xml;utf8,${theme.icons?.checkmarkIconCSS}") no-repeat 0 2px`,
                        backgroundSize: "25px 25px;",
                        paddingLeft: theme.spacing(5) + "px",
                        marginBottom: "22px",
                        lineHeight: "25px",
                        minHeight: "30px",
                        marginLeft: 0,
                        [theme.breakpoints.down("sm")]: {
                            fontSize: "1rem",
                            lineHeight: "20px",
                            minHeight: "27px",
                        },
                    },
                },
                "&.hero-area-checkmark-MO": {
                    paddingLeft: 0,
                    listStyleType: "none",
                    "& li": {
                        background: `url("data:image/svg+xml;utf8,${theme.icons?.heroCheckmarkIconCSS ??
                            theme.icons.checkmarkIconCSS}") no-repeat 0 2px`,
                        backgroundSize: "22px 22px;",
                        paddingLeft: theme.spacing(5) + "px",
                        marginBottom: "22px",
                        lineHeight: "24px",
                        minHeight: "24px",
                        marginLeft: 0,
                        [theme.breakpoints.down("sm")]: {
                            fontSize: "1rem",
                            lineHeight: "20px",
                            minHeight: "27px",
                        },
                    },
                },
            },
            "&.check-list": {
                paddingLeft: 0,
                listStyleType: "none",
                "& li": {
                    clear: "both",
                    margin: "24px 0",
                    color: "#BCBEC0",
                    textDecoration: "line-through",
                },
            },
        },
        ".row": {
            display: "flex",
            flexDirection: "column",
            [theme.breakpoints.up("md")]: {
                flexDirection: "row",
                flexWrap: "wrap",
                '& div[data-displayoption="narrow"]': {
                    flexBasis: "33%",
                },
                '& div[data-displayoption="half"]': {
                    flexBasis: "48%",
                },
                '& div[data-displayoption="small"]': {
                    flexBasis: "25%",
                },
                '& div[data-displayoption="default"]': {
                    flexBasis: "100%",
                },
            },
        },
        ".episerver-form": {
            [theme.breakpoints.only("md")]: {
                paddingRight: 24,
                paddingLeft: 24,
            },
            [theme.breakpoints.up("md")]: {
                width: "400px",
                marginRight: "auto",
                marginLeft: "auto",
            },
        },
    },
    cssLoader: {
        display: "none",
    },
    layout: {
        overflowWrap: "break-word",
        backgroundColor: theme.layout?.backgroundColor || "transparent",
    },
    skeletonBody: {
    //  minHeight: "500px",
    },
}), { name: "MOGlobal" });
